import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./Notification.css"

const NotificationPermissionModal = (props) => {
  const handleCloseModalClick = (e) => {
    e.stopPropagation();
    props.closeModal();
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        modalClassName="avendi-modal"
        className="popup_area show"
        centered
        size={"lg"}
        onClick={props.handleModalClick}
      >
        <ModalBody style={{ padding: "15px 10px", fontFamily: "Inter" }}>
          {/* <div
            className="d-flex justify-content-end"
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <button
              type="button"
              className="border-0 d-flex justify-content-center align-items-center"
              data-dismiss="modal"
              aria-label="Close"
              title="Cancel"
              onClick={handleCloseModalClick}
              style={{
                width: "40px",
                height: "40px",
                background: "black",
                borderRadius: "100%",
              }}
            >
              <IoMdClose
                className=" text-white"
                style={{ fontSize: "30px" }}
                onClick={handleCloseModalClick}
              />
            </button>
          </div> */}

          <div className="d-flex">
            <div className="pl-2 mt-10">
              <h2
                className=" mt-2 text-black"
                style={{ fontSize: "14.39px", color: "black", fontWeight: 600 }}
              >
                Notification Permission
              </h2>

              <p
                style={{
                  color: "#8F8F8F",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
                className="mt-2"
              >
                {props.message ||
                  "We need your permission to send you notifications. Please allow notifications to stay updated."}
              </p>
            </div>
          </div>

          <div className="modal-actions">
            {props.showAllowButton ? 
            <button className="notification-btn notification-btn-allow" onClick={props.handleAllowClick}>
              Allow
            </button>: <></>
            }
            <button className="notification-btn  notification-btn-close" onClick={handleCloseModalClick}>
              Close
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

NotificationPermissionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleAllowClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  showAllowButton: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

export default NotificationPermissionModal;
