import { useState, useEffect, useContext } from "react";
import { getToken } from "firebase/messaging";
import { firebaseMessaging } from "../../utils/firebase";
import NotificationPermissionModal from "./NotificationPermissionModal";
import { ProfileContext } from "../../contexts/ProfileContext";

const NotificationPermission = () => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showAllowButton, setShowAllowButton] = useState(true);
  const { setFcmToken } = useContext(ProfileContext);

  useEffect(() => {
    // Check if notifications are already enabled
    if (Notification.permission === "granted") {
      console.log("Notifications are already enabled.");
    } else if (Notification.permission === "denied") {
      // If permission is denied, inform the user to enable it manually
      setMessage(
        "You have blocked notifications. Please enable them in your browser settings."
      );
      setShowModal(true);
      setShowAllowButton(false);
    } else {
      // Show the modal if notifications are not enabled
      setShowModal(true);
    }
  }, []);

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const fcmToken = await getToken(firebaseMessaging);
        if (fcmToken) {
          setFcmToken(fcmToken);
          console.log("FCM Token:", fcmToken);
          setMessage("Notifications are enabled.");
        } else {
          setMessage("Failed to get FCM token.");
        }
      } else {
        setMessage("You will not receive notifications.");
        setShowModal(true);
        setShowAllowButton(false);
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      setMessage("An error occurred while requesting notification permission.");
      setShowModal(true);
      setShowAllowButton(false);
    }
  };

  const handleAllowClick = () => {
    requestNotificationPermission();
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <NotificationPermissionModal
          isOpen={showModal}
          handleAllowClick={handleAllowClick}
          closeModal={handleCloseModal}
          message={message}
          showAllowButton={showAllowButton}
        />
      )}
    </div>
  );
};

export default NotificationPermission;
