import { createContext, useEffect, useState } from "react";

const defaultProvider = {
  hotelDetails: null,
  properties: null,
  dineAndDrinks: null,
  fitness: null,
  spa: null,
  services: null,
  requestUpdated: false,
  setProperties: () => null,
  setHotelDetails: () => null,
  setDineAndDrinks: () => null,
};
const ProfileContext = createContext(defaultProvider);

const ProfileProvider = ({ children }) => {
  // ** States
  const [hotelDetails, setHotelDetails] = useState(defaultProvider.profile);
  const [properties, setProperties] = useState(defaultProvider.properties);
  const [dineAndDrinks, setDineAndDrinks] = useState(
    defaultProvider.dineAndDrinks
  );
  const [fitness, setFitness] = useState(defaultProvider.fitness);
  const [spa, setSpa] = useState(defaultProvider.spa);
  const [services, setServices] = useState(defaultProvider.services);
  const [requestUpdated, setRequestUpdated] = useState(
    defaultProvider.requestUpdated
  );
  const [fcmToken, setFcmToken] = useState("");

  const values = {
    hotelDetails,
    properties,
    dineAndDrinks,
    fitness,
    spa,
    services,
    requestUpdated,
    setProperties,
    setHotelDetails,
    setDineAndDrinks,
    setFitness,
    setSpa,
    setServices,
    setRequestUpdated,
    fcmToken,
    setFcmToken,
  };

  return (
    <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>
  );
};

export { ProfileContext, ProfileProvider };
