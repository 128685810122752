import { useCallback, useEffect, useState } from "react";
import {
  getUnreadChatMessageList,
  markAsReadChatMessage,
} from "../../services/chatService";
import { getValue } from "../../utils/object";
import { getUser } from "../../utils/sessionManager";
import "./UnreadMessage.css";
import { DynamicRoutes } from "../../constants/routes";
import { appService } from "../../services/appService";

const currentUser = getUser();
const guestId = getValue(currentUser, "_id");

const UnreadMessages = () => {
  const [unreadMessages, setUnreadMessages] = useState([]);

  const fetchUnreadMessages = useCallback(async () => {
    try {
      const response = await getUnreadChatMessageList({ guestId: guestId });
      console.log(response, "response");
      setUnreadMessages(getValue(response, "messages", []));
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchUnreadMessages();
  }, [fetchUnreadMessages]);

  const handleReplyClick = ({ request }) => {
    appService.goTo(
      DynamicRoutes.MyRequestDetails,
      { id: request._id },
      { item: request }
    );
  };

  const handleMarkAsReadClick = async ({ requestId }) => {
    await markAsReadChatMessage({ requestId });
    fetchUnreadMessages()
  };

  return (
    <section className="mtabs_items_area" id="page__request-status-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
            <div id="mtabs">
              <div className="all_tab_items">
                {unreadMessages.length === 0 ? (
                  <div style={{textAlign: 'center', fontSize: '20px'}}>No Messages</div>
                ) : (
                  <>
                    {unreadMessages.map((message) => {
                      const msgList = getValue(message, "messages", []);
                      const activeMessage = msgList[msgList.length - 1];
                      return (
                        <div
                          className="guest-status-card"
                          style={{ marginBottom: "20px" }}
                          key={getValue(message, "_id")}
                        >
                          <p className="unread-message-content">
                            {getValue(activeMessage, "message")}
                          </p>
                          <div className="msg-button-section">
                            <button
                              onClick={() =>
                                handleReplyClick({
                                  request: message,
                                })
                              }
                            >
                              Reply
                            </button>
                            <button
                              onClick={() =>
                                handleMarkAsReadClick({
                                  requestId: getValue(message, "_id"),
                                })
                              }
                            >
                              Mark As Read
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnreadMessages;
