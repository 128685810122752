import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";
import ValentineOfferImg from "../../assets/images/offers/ParkVillageValentine.png";

const ValentineModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      modalClassName="avendi-modal"
      // className="popup_area show"
      centered
      size={"sm"}
    >
      <ModalBody style={{ padding: "0px" }}>
        <div
          className="d-flex justify-content-end"
          style={{ position: "absolute", right: 0, top: 0 }}
        >
          <button
            type="button"
            className="border-0 d-flex justify-content-center align-items-center"
            data-dismiss="modal"
            aria-label="Close"
            title="Cancel"
            onClick={props.closeModal}
            style={{
              width: "40px",
              height: "40px",
              background: "black",
              borderRadius: "100%",
            }}
          >
            <IoMdClose
              className=" text-white"
              style={{ fontSize: "30px" }}
              onClick={props.closeModal}
            />
          </button>
        </div>
        <img src={ValentineOfferImg} alt="Valentine" />
      </ModalBody>
    </Modal>
  );
};

ValentineModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ValentineModal;
