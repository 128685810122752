import PropTypes from "prop-types";
import { IoChatboxEllipsesOutline } from "react-icons/io5";

const InboxTooltip = ({ chatCount }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        cursor: "pointer",
        animation: chatCount
          ? "chat-animation 1s ease-in-out infinite"
          : "none",
      }}
      className="cart"
    >
      <IoChatboxEllipsesOutline
        size={24}
        style={{
          cursor: "pointer",
        }}
        color={"#000"}
      />
      {chatCount > 0 ? <p>{chatCount}</p> : <></>}
    </div>
  );
};

InboxTooltip.propTypes = {
  chatCount: PropTypes.number,
};

export default InboxTooltip;
